<template>
	<div data-route>
		<page-header
			heading="Users"
		/>
		<div data-element="main">
			<table-view
				:table="getTable"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader    from '@/components/ui/PageHeader';
	import TableView     from '@/components/ui/views/tableView/TableView';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		metaInfo: {
			title: 'Users'
		},
		components: {
			PageHeader,
			TableView
		},
		data: () => ({
			users: [],
			searchString: '',
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Name',
						'Teacher',
						'Admin',
						'Email Verified'
					],
					rows: this.users.map((user) => {
						return {
							route: `/admin/users/${user.id}`,
							columns: [
								this.getNameString(user),
								user.isTeacher ? 'Yes' : '--',
								user.isAdmin ? 'Yes' : '--',
								user.verified ? 'Yes' : '--'
							]
						};
					})
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setUsers();
		},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setUsers();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setUsers();
			},
			getNameString (user) {
				if (!user) {
					return false;
				}
				return `${user.firstName} ${user.lastName}`;
			},
			async setUsers () {
				const response = await api.admin.getAllUsers({
					include: 'metadata',
					searchString: this.searchString,
					per: this.pagination.perPage,
					page: this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.users = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
